<template>
    <div class="ui grid m-0 p-0">
        <div class="ui grid row p-0 m-0">
            <sidebar/>
            <div class="thirteen wide column p-0">
                <navbar/>

                <div class="mt-1">
                    <div class="ui stackable grid m-0">
                        <div class="ui row p-0">
                            <div class="wide column">

                                <div class="row padded">
                                    <div class="column">
                                        <h2 class="ui dividing padded header app-blue" style="font-size: 1.2em;">
                                            Résultat de recherche ({{count}})
                                        </h2>
                                    </div>

                                </div>
                                <div class="row mt-1">
                                    <div class="ui form" @keypress.enter="getFolders">
                                        <div class="five fields">
                                            <div class="field">
                                                <input type="text" class="placeholder-color" placeholder="Réf"
                                                       v-model="search_fields.numero">
                                            </div>
                                            <div class="field">
                                                <input type="text" class="placeholder-color" placeholder="Réfécence Cie"
                                                       v-model="search_fields.reference_cie"/>
                                            </div>
                                            <div class="field">
                                                <v-select :options="marque_list" placeholder="Véh"
                                                          class="placeholder-color"
                                                          v-model="search_fields.marque_assure" @input="getModelList"/>
                                            </div>
                                            <div class="field">
                                                <v-select :options="model_list" class="placeholder-color"
                                                          placeholder="Type" v-model="search_fields.modele_assure"/>
                                            </div>
                                            <div class="field">
                                                <input type="text" class="placeholder-color"
                                                       placeholder="Nom de l'assuré" v-model="search_fields.nom_assure">
                                            </div>


                                        </div>

                                        <div class="five fields">
                                            <div class="field">
                                                <input type="text" class="placeholder-color"
                                                       placeholder="Prénom de l'assuré"
                                                       v-model="search_fields.prenom_assure">
                                            </div>
                                            <div class="field">
                                                <input type="text" class="placeholder-color"
                                                       placeholder="IMM de l'assuré"
                                                       v-model="search_fields.immat_assure">
                                            </div>

                                            <div class="field">
                                                <input type="text" class="placeholder-color"
                                                       placeholder="Nom de l'adverse"
                                                       v-model="search_fields.nom_adverse">
                                            </div>
                                            <div class="field">
                                                <input type="text" class="placeholder-color"
                                                       placeholder="Prénom de l'adverse"
                                                       v-model="search_fields.prenom_adverse">
                                            </div>
                                            <div class="field">
                                                <input type="text" class="placeholder-color"
                                                       placeholder="IMM de l'adverse"
                                                       v-model="search_fields.immat_adverse">
                                            </div>

                                        </div>

                                        <div class="three fields">
                                            <div class="field">
                                                <input type="text" class="placeholder-color"
                                                       placeholder="Nom de réparateur"
                                                       v-model="search_fields.nom_reparateur">
                                            </div>
                                        </div>

                                        <div class="flex justify-center">
                                            <button class="ui positive right labeled icon button" :class="{'disabled': isLoading}"
                                                    @click.prevent="getFolders" style="border-radius: 6px;">
                                                <i class="search icon"></i>
                                                Chercher
                                            </button>

                                            <button class="ui button" @click="clearFields" :class="{'disabled': isLoading}"
                                                    style="border-radius: 6px;">
                                                Re-initialiser
                                            </button>
                                        </div>
                                        <div style="clear: both"></div>

                                    </div>
                                </div>

                                <div class="row">

                                    <div class="ui accordion">
                                        <div class="title" style="font-size: 15px">
                                            <i class="dropdown icon"></i>
                                            <u>Recherche avancée</u>
                                        </div>
                                        <div class="content">

                                            <div class="ui form">

                                                <div class="four fields">
                                                    <div class="field">
                                                        <v-select label="label" :options="assurance_list"
                                                                  placeholder="Compagnie"
                                                                  v-model="search_fields.assurance"
                                                                  class="placeholder-color">
                                                        </v-select>
                                                    </div>
                                                    <div class="field">
                                                        <v-select label="label" :options="courtier_list"
                                                                  placeholder="Agent/Courtier"
                                                                  v-model="search_fields.courtier"
                                                                  class="placeholder-color">
                                                        </v-select>
                                                    </div>
                                                    <div class="field">
                                                        <v-select placeholder="Type dossier" :reduce="type => type._id"
                                                                  :options="type_dossier_options"
                                                                  class="placeholder-color"
                                                                  v-model="search_fields.type_dossier"/>
                                                    </div>

                                                    <div class="field">
                                                        <v-select placeholder="Status" :reduce="status => status._id"
                                                                  class="placeholder-color"
                                                                  :options="status_options"
                                                                  v-model="search_fields.dossier_status"/>
                                                    </div>

                                                </div>

                                                <div class="four fields">

                                                    <div class="field">
                                                        <dateMilliRange class="custom-date placeholder-color"
                                                                        label="Date de Mission" id="date_mission"
                                                                        v-model="date_creation"/>
                                                    </div>

                                                    <div class="field">
                                                        <dateMilliRange class="custom-date placeholder-color"
                                                                        label="Date sinistre" id="date_sinistre"
                                                                        v-model="date_sinistre"/>
                                                    </div>

                                                    <div class="field">
                                                        <dateMilliRange class="custom-date placeholder-color"
                                                                        :label="'Date de cloture'" id="date_cloture"
                                                                        v-model="date_cloture"/>
                                                    </div>

                                                    <div class="field">
                                                        <dateMilliRange class="custom-date placeholder-color"
                                                                        :label="'Date de paiement'" id="date_paiement"
                                                                        :no-value-to-custom-elem="true"
                                                                        v-model="date_paiement"/>
                                                    </div>
                                                </div>

                                                <div class="two fields">
                                                    <div class="field">
                                                        <v-select placeholder="Cadre d'expertise" class="placeholder-color"
                                                                  :options="cadre_expertise"
                                                                  v-model="search_fields.cadre_expertise"/>
                                                    </div>

                                                    <div class="field">
                                                        <UserGroupSelect  placeholder="Disptcher" class="placeholder-color"
                                                                          v-model="search_fields.dispatcher"
                                                                          :filterFront="{type: 'dispatcher'}"/>
                                                    </div>
                                                </div>

                                                <div class="two fields">
                                                    <div class="field">
                                                        <label> Montant devis </label>
                                                        <div class="two fields">
                                                            <div class="field">
                                                                <input type="number" class="placeholder-color"
                                                                       placeholder="Min"
                                                                       v-model.number="search_fields.montant_devis.min">
                                                            </div>

                                                            <div class="field">
                                                                <input type="number" class="placeholder-color"
                                                                       placeholder="Max"
                                                                       v-model.number="search_fields.montant_devis.max">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="field">
                                                        <label> Montant de la facture </label>
                                                        <div class="two fields">
                                                            <div class="field">
                                                                <input type="number" class="placeholder-color"
                                                                       placeholder="Min"
                                                                       v-model.number="search_fields.montant_facture.min">
                                                            </div>

                                                            <div class="field">
                                                                <input type="number" class="placeholder-color"
                                                                       placeholder="Max"
                                                                       v-model.number="search_fields.montant_facture.max">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div class="ui divider" style="margin-top:0"></div>
                                    <!--<advancedSearch></advancedSearch>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row centered">
            <div class="fourteen wide column">
                <div class="ui small basic right floated button export" @click="exportExcel">
                    <i class="file excel outline green icon"></i>
                </div>
                <div style="clear: both"></div>
                <div class="ui segment">
                    <div class="ui inverted dimmer" :class="{'active': isLoading}">
                        <div class="ui text loader"> Chargement </div>
                    </div>

                    <table class="ui selectable celled table">
                        <thead>
                        <tr>
                            <th @click="sortFolders('numero')" style="width: auto;">
                                Réf
                                <i v-if="sort.numero" class="long arrow alternate icon float-right"
                                   :class="sort.numero === 1 ? 'up' : 'down'"></i>
                            </th>
                            <th @click="sortFolders('propri.nom')" style="width: auto;">
                                Assuré
                                <i v-if="sort['propri.nom']"
                                   class="long arrow alternate icon float-right"
                                   :class="sort['propri.nom'] === 1 ? 'up' : 'down'"></i>
                            </th>
                            <th @click="sortFolders('vehicule.marque.label')" style="width: auto;">
                                VH
                                <i v-if="sort['vehicule.marque.label']"
                                   class="long arrow alternate icon float-right"
                                   :class="sort['vehicule.marque.label'] === 1 ? 'up' : 'down'"></i>
                            </th>

                            <th @click="sortFolders('vehicule.model.label')" style="width: auto;">
                                Modéle
                                <i v-if="sort['vehicule.model.label']"
                                   class="long arrow alternate icon float-right"
                                   :class="sort['vehicule.model.label'] === 1 ? 'up' : 'down'"></i>
                            </th>

                            <th @click="sortFolders('vehicule.n_immat')" style="width: auto;">
                                Imm
                                <i v-if="sort['vehicule.n_immat']"
                                   class="long arrow alternate icon float-right"
                                   :class="sort['vehicule.n_immat'] === 1 ? 'up' : 'down'"></i>
                            </th>
                            <th @click="sortFolders('assurance.date_sinistre')" style="width: auto;">
                                D.sinistre
                                <i v-if="sort['assurance.date_sinistre']"
                                   class="long arrow alternate icon float-right"
                                   :class="sort['assurance.date_sinistre'] === 1 ? 'up' : 'down'"></i>
                            </th>
                            <th @click="sortFolders('courtier.label')" style="width: auto;">
                                Agent/courtier
                                <i v-if="sort['courtier.label']"
                                   class="long arrow alternate icon float-right"
                                   :class="sort['courtier.label'] === 1 ? 'up' : 'down'"></i>
                            </th>
                            <th @click="sortFolders('type_dossier')" style="width: auto;">
                                Type dossier
                                <i v-if="sort.type_dossier"
                                   class="long arrow alternate icon float-right"
                                   :class="sort.type_dossier === 1 ? 'up' : 'down'"></i>
                            </th>
                            <th @click="sortFolders('date_cloture')" style="width: auto;">
                                D.cloture
                                <i v-if="sort.date_cloture"
                                   class="long arrow alternate icon float-right"
                                   :class="sort.date_cloture === 1 ? 'up' : 'down'"></i>
                            </th>
                            <th @click="sortFolders('date_paiement')" style="width: auto;">
                                D.paiement
                                <i v-if="sort.date_paiement"
                                   class="long arrow alternate icon float-right"
                                   :class="sort.date_paiement === 1 ? 'up' : 'down'"></i>
                            </th>
                            <th @click="sortFolders('new_total_net')" style="width: auto;">
                                Total Net
                                <i v-if="sort['new_total_net']"
                                   class="long arrow alternate icon float-right"
                                   :class="sort['new_total_net'] === 1 ? 'up' : 'down'"></i>
                            </th>
                            <!--<th>Date de création</th>-->
                            <th @click="sortFolders('dispatcher.username')" style="width: auto;">
                                Dispatcher
                                <i v-if="sort['dispatcher.username']"
                                   class="long arrow alternate icon float-right"
                                   :class="sort['dispatcher.username'] === 1 ? 'up' : 'down'"></i>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="!dossier_list.length">
                            <td colspan="99"> Pas de données disponibles</td>
                        </tr>
                        <tr style="cursor: pointer" v-for="dossier in dossier_list" @click="edit(dossier)"
                            :class="getColorByStatus(dossier.status)">
                            <td class="danger-cell numero"> {{ dossier.numero || dash}}</td>

                            <td>{{dossier.propri ? (dossier.propri.nom || dash) + ' '+
                                (dossier.propri.prenom || dash) : dash }}
                            </td>

                            <td>{{(dossier.vehicule && dossier.vehicule.marque) ?
                                dossier.vehicule.marque.label || dash : dash}}
                            </td>

                            <td>{{(dossier.vehicule && dossier.vehicule.model) ?
                                dossier.vehicule.model.label || dash : dash}}
                            </td>

                            <td> {{ dossier.vehicule ? (dossier.vehicule.n_immat || dash) : dash}}</td>
                            <td>{{dossier.assurance.date_sinistre ?
                                $store.getDate(dossier.assurance.date_sinistre) || dash :
                                dash}}
                            </td>
                            <td>{{dossier.courtier ? dossier.courtier.label || dash : dash}}</td>
                            <td> {{ dossier.type_dossier || dash}}</td>
                            <td> {{ $store.getDate(dossier.date_cloture) || dash}}</td>
                            <td> {{ $store.getDate(dossier.date_paiement) || dash}}</td>
                            <td style="white-space: nowrap">
                                {{ (dossier.status === 'active' || dossier.status === 'suspended') ? 0 :
                                numberWithSpaces(dossier.total_net)}}
                            </td>

                            <!--<td> {{ $store.getDate(dossier.date_creation, 'DD-MM-YYYY') || dash}}</td>-->

                            <td>
                                {{ dossier.dispatcher ? (dossier.dispatcher.username || dash) : dash }}
                            </td>

                        </tr>
                        </tbody>

                        <tfoot>
                        <tr>
                            <th colspan="99">
                                <div>
                                    <pagination :limit="limit" :current_page="page" :count="count"
                                                @setPage="setPage" slot="pagination"/>
                                </div>
                            </th>
                        </tr>
                        </tfoot>

                    </table>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import sidebar from "../components/sidebar";
    import navbar from '@/components/navbar.vue';
    import pagination from "@/components/pagination.vue";
    import dateMilliRange from '@/components/search/dateMilliRange';
    import UserGroupSelect from "@/components/UserGroupSelect.vue";

    export default {
        name: "search",
        components: {
            sidebar, navbar, pagination, dateMilliRange, UserGroupSelect
        },
        props: {
            search_prop: String
        },
        data() {
            return {
                dossier_list: [],
                page: 1,
                limit: 10,
                dash: '--------',
                count: 0,
                search: '',
                search_fields: {},
                assurance_list: [],
                courtier_list: [],
                marque_list: [],
                model_list: [],
                dates: null,
                date_creation: null,
                date_sinistre: null,
                date_validation: null,
                date_paiement: null,
                date_cloture: null,
                sort: {'numero': 1},
                type_dossier_options: [
                    {_id: 'reforme', label: 'Reforme'},
                    {_id: 'reparation', label: 'Réparation'}
                ],
                status_options: [
                    {_id: 'active', label: 'Actif'},
                    {_id: 'cloturer', label: 'Clôturé'},
                    {_id: 'paye', label: 'Payé'},
                    {_id: 'suspended', label: 'En instance'},
                ],
                isLoading: false
            }
        },
        computed: {
            getSearchQuery() {
                return this.$store.state.search_fields;
            },
            cadre_expertise(){
                return _.sortBy(this.$store.state.cadre_expertise)
            }
        },
        methods: {
            sortFolders(field) {
                if (Object.keys(this.sort)[0] !== field) {
                    this.sort = {};
                    this.sort[field] = -1
                }

                if (this.sort[field] === 1) {
                    this.sort[field] = -1
                } else {
                    this.sort[field] = 1
                }

                this.getFolders();
            },
            getFolders() {
                log('getFolders');
                this.isLoading = true;

                let search_query = {};
                search_query = this.searchQuery();
                let query = {type: 'dossier', ...search_query}
                let data = {
                    collection: this.$SHARED.collections.documents,
                    query: query,
                    concat_pipeline: [
                        {
                            $addFields: {
                                new_total_net: {
                                    $cond: {
                                        if: {$in: ["$status", ["active", "suspended"]]},
                                        then: 0,
                                        else: "$total_net"
                                    }
                                }
                            }
                        }
                    ],
                    options: {
                        page: parseInt(this.page),
                        limit: parseInt(this.limit)
                    }
                };
                if (!_.isEmpty(this.sort)) {
                    data.options.sort = JSON.parse(JSON.stringify(this.sort))
                    console.log(Object.keys(this.sort)[0]);
                    if (Object.keys(this.sort)[0] !== 'numero') {
                        data.options.sort['numero'] = 1;
                    }
                }

                this.$store.requestFind(data).then(ar => {
                    this.dossier_list = ar.docs;
                    this.count = ar.count;
                }, () => {
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                }).finally(() => {
                    this.isLoading = false;
                })
                this.$store.state.search_fields.search_fields = this.search_fields;
                this.$store.state.search_fields.date_creation = this.date_creation;
                this.$store.state.search_fields.date_sinistre = this.date_sinistre;
                this.$store.state.search_fields.date_paiement = this.date_paiement;
                this.$store.state.search_fields.date_cloture = this.date_cloture;
            },
            setPage(page) {
                this.page = page;
                this.getFolders();
            },
            edit(dossier) {
                this.$router.push({name: 'folderEdit', params: {task: null, dossier_prop: dossier}});
            },
            searchQuery() {
                console.log("searchQuery");
                let text_query = []
                let dates_query = []

                if (this.date_creation) {
                    dates_query.push({date_creation: this.dateToQuery(this.date_creation)})
                }

                if (this.date_sinistre) {
                    dates_query.push({"assurance.date_sinistre": this.dateToQuery(this.date_sinistre)})
                }

                if (this.date_paiement) {
                    dates_query.push({"date_paiement": this.dateToQuery(this.date_paiement)})
                }

                if (this.date_cloture) {
                    dates_query.push({"date_cloture": this.dateToQuery(this.date_cloture)})
                }

                let query = {}
                if (dates_query.length && text_query.length) {
                    query.$and = [{$and: dates_query}, {$or: text_query}]
                } else if (text_query.length) {
                    query.$or = text_query;
                } else if (dates_query.length) {
                    query.$and = dates_query;
                }

                if (this.search_fields.numero) {
                    query["numero"] = {$regex: this.search_fields.numero, $options: 'i'};
                }

                if (this.search_fields.reference_cie) {
                    query["reference_cie"] = {$regex: this.search_fields.reference_cie, $options: 'i'};
                }

                if (this.search_fields.prenom_assure) {
                    query["propri.prenom"] = {$regex: this.search_fields.prenom_assure, $options: 'i'};
                }

                if (this.search_fields.nom_assure) {
                    query["propri.nom"] = {$regex: this.search_fields.nom_assure, $options: 'i'};
                }


                if (this.search_fields.nom_adverse) {
                    query["adverse.nom"] = {$regex: this.search_fields.nom_adverse, $options: 'i'};
                }

                if (this.search_fields.prenom_adverse) {
                    query["adverse.prenom"] = {$regex: this.search_fields.prenom_adverse, $options: 'i'};
                }

                if (this.search_fields.marque_assure) {
                    query["vehicule.marque._id"] = {$regex: this.search_fields.marque_assure._id, $options: 'i'};
                }

                if (this.search_fields.modele_assure) {
                    query["vehicule.model._id"] = {$regex: this.search_fields.modele_assure._id, $options: 'i'};
                }

                if (this.search_fields.immat_adverse) {
                    query["adverse.vehicule.n_immat"] = {$regex: this.search_fields.immat_adverse, $options: 'i'};
                }

                if (this.search_fields.immat_assure) {
                    query["vehicule.n_immat"] = {$regex: this.search_fields.immat_assure, $options: 'i'};
                }

                if (this.search_fields.nom_reparateur) {
                    query["nom_reparateur"] = {$regex: this.search_fields.nom_reparateur, $options: 'i'};
                }

                if (this.search_fields.type_dossier) {
                    query.type_dossier = this.search_fields.type_dossier;
                }
                if (this.search_fields.dossier_status) {
                    if (this.search_fields.dossier_status === 'cloturer') {
                        query.status = 'cloturer'
                    } else if (this.search_fields.dossier_status === 'active') {
                        query.status = 'active'
                    } else if (this.search_fields.dossier_status === 'paye') {
                        query.status = 'paye'
                    } else if (this.search_fields.dossier_status === 'suspended') {
                        query.status = 'suspended'
                    }

                }
                if (this.search_fields.assurance) {
                    query['assurance._id'] = this.search_fields.assurance._id
                }
                if (this.search_fields.courtier) {
                    query['courtier._id'] = this.search_fields.courtier._id
                }

                if (this.search_fields.cadre_expertise) {
                    query['nature'] = this.search_fields.cadre_expertise
                }

                if (this.search_fields.dispatcher) {
                    query['dispatcher.username'] = this.search_fields.dispatcher.username
                }

                if(this.search_fields.montant_devis.min && this.search_fields.montant_devis.max){
                    query['montant_devis'] = {
                        $gte: this.search_fields.montant_devis.min,
                        $lte: this.search_fields.montant_devis.max
                    }
                }else {
                    if(this.search_fields.montant_devis.min){
                        query['montant_devis'] = {$gte: this.search_fields.montant_devis.min}
                    }
                    if(this.search_fields.montant_devis.max){
                        query['montant_devis'] = {$lte: this.search_fields.montant_devis.max}
                    }
                }

                if(this.search_fields.montant_facture.min && this.search_fields.montant_facture.max){
                    query['montant_facture'] = {
                        $gte: this.search_fields.montant_facture.min,
                        $lte: this.search_fields.montant_facture.max
                    }
                }else {
                    if(this.search_fields.montant_facture.min){
                        query['montant_facture'] = {$gte: this.search_fields.montant_facture.min}
                    }
                    if(this.search_fields.montant_facture.max){
                        query['montant_facture'] = {$lte: this.search_fields.montant_facture.max}
                    }
                }


                log("QUERY", query);
                return query;
            },
            dateToQuery(date) {
                let field_query = {};

                if (date.start)
                    field_query.$gte = date.start;
                if (date.end)
                    field_query.$lte = date.end;

                return field_query;
            },
            numberWithSpaces(number) {
                if (number == null) {
                    return null
                }
                number = number.toFixed(2)
                let parts = number.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(".");
            },
            getColorByStatus(status) {
                switch (status) {
                    case 'active':
                        return 'active-line'
                    case 'cloturer':
                        return 'cloture-line'
                    case 'paye':
                        return 'paye-line'
                }
            },
            getAssurancesList() {
                log('getAssurancesList');
                const data = {
                    collection: this.$SHARED.collections.assurance,
                    query: {},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.assurance_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getCourtierList() {
                log('getCourtierList');
                const data = {
                    collection: this.$SHARED.collections.courtier,
                    query: {},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.courtier_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getMarqueList() {
                log('getMarques');
                const data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: {type: 'marque'},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.marque_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getModelList() {
                log('getModels');
                if (!this.search_fields.marque_assure)
                    return;

                const data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: {type: 'model', id_pere: this.search_fields.marque_assure._id},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.model_list = ar.docs;
                }, code => {
                    this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages.technical_error});
                })
            },
            clearFields() {
                this.search_fields = {};
                this.date_creation = null;
                this.date_sinistre = null;
                this.date_paiement = null;
                this.date_cloture = null;
                this.$store.state.search_fields.search_fields = {}
                this.$store.state.search_fields.date_creation = null
                this.$store.state.search_fields.date_sinistre = null
                this.$store.state.search_fields.date_paiement = null
                this.$store.state.search_fields.date_cloture = null
                this.getFolders()
            },
            exportExcel() {
                log('exportExcel');
                let query = this.searchQuery()
                query.type = 'dossier';
                this.$store.dispatchAsync(this.$SHARED.services.exportExcel, query, true).then(data => {
                    let file_path = this.$store.state.SERVER_ADDRESS + '/' + this.$SHARED.services.export + '/' + data.destination;
                    let a = document.createElement('a');
                    a.href = file_path;
                    a.setAttribute('download', file_path);
                    a.setAttribute('target', '_blanc');
                    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                })
            }
        },
        updated() {
            $('.ui.accordion').accordion({exclusive: true});
        },
        mounted() {
            $('.ui.accordion').accordion({exclusive: true});
            this.getFolders();
            this.getMarqueList();
            this.getModelList();
            this.getAssurancesList();
            this.getCourtierList();
        },
        beforeMount() {
            this.search_fields = this.getSearchQuery.search_fields;
            this.date_creation = this.getSearchQuery.date_creation;
            this.date_sinistre = this.getSearchQuery.date_sinistre;
            this.date_paiement = this.getSearchQuery.date_paiement;
            this.date_cloture = this.getSearchQuery.date_cloture;
        }
    }
</script>

<style scoped>

</style>